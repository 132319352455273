.btn {
    background-color: $color-black;
    color: $color-white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    height: 54.84px;
    font-weight: normal;
    font-size: $font-size-16;
    width: 100%;
    transition: all 0.3s ease-in;
    
    &:hover {
        transition: all 0.3s ease-in;
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;
    }
}
