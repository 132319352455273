// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

a.btn-black {
    background: $color-black;
    width: 260px;
    height: 100px;
    border-radius: 10px;
    margin-top: 35px;
    transition: 0.5s;
    h5 {
        font-weight: 500;
        font-size: $font-size-20;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 5px;
        transition: 0.5s;
    }
    &:hover {
        background: $color-white;
        border: 1px solid $color-black;
        transition: 0.5s;
        h5 {
            color: $color-black;
            transition: 0.5s;
        }
    }
}

a.btn-plus {
    background: $color-black;
    width: 300px;
    height: 60px;
    transition: 0.3s;
    .icon {
        img {
            transition: 0.5s;
        }
    }
    h5 {
        font-weight: 500;
        font-size: $font-size-14;
        color: $color-white;
        text-transform: uppercase;
        line-height: 1.5;
    }
    &:hover {
        background: $color-1;
        transition: 0.3s;
        img {
            transform: rotate(180deg);
            transition: 0.5s;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-header {
    padding: 50px 0 0 0;
    .left-side {
        width: 35%;
        .text-side {
            padding-left: 16%;
            padding-right: 25px;
            margin-bottom: 100px;
            h3 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-black;
            }
        }
    }
    .right-side {
        width: 65%;
        .text-side {
            padding: 50px 35px 0 35px;
            .left {
                width: 20%;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-black;
                    text-transform: uppercase;
                    text-align: right;
                }
            }
            .center {
                width: 55%;
                padding: 0 25px;
            }
            .right {
                h4 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-black;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                }
                .arrow-text {
                    h5 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-black;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1660px) {
        .left-side {
            .text-side {
                margin-bottom: 155px;
                h3 br {
                    display: none;
                }
            }
        }
        .right-side {
            .text-side {
                padding: 25px 35px;
            }
        }
        @media screen and (max-width: 1550px) {
            .left-side {
                .text-side {
                    margin-bottom: 170px;
                }
            }
            @media screen and (max-width: 1470px) {
                padding: 0 0 50px 0;
                flex-direction: column-reverse;
                .right-side {
                    width: 100%;
                    .text-side {
                        padding: 50px 5%;
                        justify-content: space-between;
                    }
                }
                .left-side {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .text-side {
                        margin-bottom: 0;
                        padding-left: 5%;
                        padding-right: 50px;
                        width: 50%;
                    }
                }
                @media screen and (max-width: 1150px) {
                    .right-side {
                        .text-side {
                            flex-direction: column;
                            .left {
                                width: 100%;
                                h4 {
                                    text-align: initial;
                                }
                            }
                            .center {
                                width: 100%;
                                padding: 25px 0;
                            }
                        }
                    }
                    .left-side {
                        flex-direction: column;
                        .text-side {
                            margin-bottom: 35px;
                            padding: 0 5%;
                            width: 100%;
                            text-align: center;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .right-side {
                            .text-side {
                                .left h4, .right h4 {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .left-side {
                                .image-side {
                                    flex-direction: column;
                                    .image:first-child {
                                        margin-bottom: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    .text-side {
        background: #C1422012;
        width: 35%;
        padding-left: 5%;
        padding-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: $color-black;
            line-height: 1.3;
            padding-bottom: 15px;
        }
    }
    .image-side {
        width: 65%;
        background: url("../images/accueil_section03_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        .text-side {
            width: 100%;
            padding: 75px 5%;
        }
        .image-side {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/accueil_section03_img01_m.jpg") no-repeat;
            }
        }
    }
}

#section-image-text {
    padding: 75px 0;
    margin: 50px 0;
    position: relative;
    .text-side {
        width: 45%;
        padding-left: 50px;
        padding-right: 10%;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: $color-black;
            line-height: 1.3;
            padding-bottom: 15px;
        }
        a.btn-black {
            width: 295px;
        }
    }
    .image-side {
        width: 55%;
    }
    &::before {
        content: '';
        background: #D2D2D03C;
        z-index: -1;
        width: 65%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
    @media screen and (max-width: 1410px) {
        .text-side {
            padding-right: 5%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .image-side {
                width: 70%;
                margin-bottom: 50px;
            }
            .text-side {
                padding: 0 5%;
                width: 100%;
            }
            &::before {
                display: none;
            }
            @media screen and (max-width: $size-sm-max) {
                padding: 75px 5%;
                margin: 0;
                .image-side {
                    width: 100%;
                }
                .text-side {
                    padding: 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    .text-side {
                        a.btn-black {
                            width: 230px;
                            height: 80px;
                        }
                    }
                }
            }
        }
    }   
}

/*******************************************************************************
* MARBRE
*******************************************************************************/

#section-title {
    padding: 100px 5% 50px 5%;
    .left-side {
        width: 40%;
        margin-right: 10%;
        h2 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-black;
            text-transform: uppercase;
            line-height: 1.3;
        }
    }
    .right-side {
        width: 46%;
        p {
            font-size: $font-size-20;
        }
    }
    @media screen and (max-width: 1600px) {
        .left-side {
            width: 50%;
        }
        @media screen and (max-width: 1425px) {
            .left-side {
                width: 55%;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 35px 0;
                }
                .right-side {
                    width: 100%;
                    p br {
                        display: none;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        h2 {
                            font-size: 2.8rem;
                            br {
                                display: none;
                            }
                        }
                    }
                    .right-side {
                        p {
                            font-size: 16px;
                        }
                    }
                    @media screen and (max-width: 375px) {
                        .left-side {
                            h2 {
                                font-size: 2.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    padding: 0 5%;
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        .left-side {
            padding: 0 0 25px 0;
        }
    }
}

#section-carousel {
    padding: 100px 5% 50px 5%;
    position: relative;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #202124;
        padding-bottom: 35px;
        padding-left: 25px;
        @media screen and (max-width: $size-xs-max) {
            padding-left: 0;
        }
    }
    .main-content {
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-16;
                    color: #202124;
                    padding: 15px 0 0 15px;
                }
            }
        }
        .owl-nav {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: -55px;
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
            @media screen and (max-width: $size-xs-max) {
                display: none;
            }
        }           
    }
}

#section-text-btn {
    background: #DFB22F0A;
    padding: 100px 35px;
    margin: 0 5% 50px 5%;
    .text-side {
        width: 60%;
        margin-right: 15%;
        h3 {
            font-weight: normal;
            font-size: $font-size-36;
            color: $color-black;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        .text-side {
            width: 100%;
            margin: 0 0 75px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            margin: 0 0 50px 0;
            .text-side h3 {
                text-align: center;
            }
            @media screen and (max-width: 360px) {
                .text-side h3 {
                    font-size: 1.65rem;
                }
            }
        }
    }
}


/*******************************************************************************
* GRANIT
*******************************************************************************/

#section-link-img-text {
    padding: 75px 5%;
    margin: 50px 0;
    position: relative;
    .left-side {
        background: $color-1;
        margin-right: 15px;
        padding: 65px 35px;
        width: 15%;
        a.link {
            font-weight: bold;
            font-size: $font-size-20;
            color: $color-white;
            text-transform: uppercase;
            transition: 0.3s;
            &:hover {
                color: $color-black;
                transition: 0.3s;
            }
        }
    }
    .right-side {
        margin-left: 50px;
        padding-top: 65px;
        padding-right: 25px;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: $color-black;
            text-transform: uppercase;
            padding-bottom: 35px;
        }
        .arrow-text {
            h5 {
                font-weight: 500;
                font-size: $font-size-20;
                color: $color-black;
            }
        }
    }
    &::before {
        content: '';
        background: #D2D2D03C;
        z-index: -1;
        width: 60%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 5%;
    }
    @media screen and (max-width: $size-md-max) {
        margin: 0;
        flex-direction: column;
        .left-side {
            width: 100%;
        }
        &::before {
            width: 100%;
            height: 60%;
            top: initial;
            bottom: 0;
            margin-right: 0;
        }
        @media screen and (max-width: $size-sm-max) {
            .right-side {
                margin-left: 25px;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    padding: 50px 25px;
                }
                .right-side {
                    margin-left: 0;
                }
            }
        }
    }
}

/*******************************************************************************
* QUARTZ
*******************************************************************************/







/*******************************************************************************
* COMPTOIRS
*******************************************************************************/

#section-text-image.comptoirs {
    @media screen and (max-width: 1455px) {
        .text-side {
            width: 45%;
            padding: 35px 50px 35px 5%;
        }
        @media screen and (max-width: 1150px) {
            .text-side {
                width: 100%;
                padding: 75px 5%;
            }
        }
    }
}

#section-image-text {
    .text-side {
        p {
            font-size: $font-size-20;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 35px 5% 75px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-black;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnées {
    padding: 100px 5%;
    position: relative;
    margin: -250px 7% 0 7%;
    background: $color-white;
    .left-side {
        width: 55%;
        margin-right: 5%;
        h2 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-black;
            text-transform: uppercase;
            padding-bottom: 75px;
        }
        .heures-info {
            .left {
                width: 35%;
                margin-right: 150px;
                h3 {
                    font-weight: bold;
                    font-size: $font-size-38;
                    color: $color-black;
                    text-transform: initial;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #C14220;
                    margin-bottom: 25px;
                }
                h4 {
                    font-weight: bold;
                    font-size: $font-size-18;
                    color: $color-black;
                    line-height: 2.2;
                }
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: $color-black;
                    line-height: 2.2;
                }
            }
            .right {
                margin-top: 80px;
                .bloc-info {
                    .icon {
                        width: 18%;
                    }
                    .text {
                        width: 100%;
                        h5 {
                            font-weight: normal;
                            font-size: $font-size-20;
                            color: #707070;
                        }
                        h4 {
                            font-weight: bold;
                            font-size: $font-size-20;
                            color: #707070;
                        }
                    }
                }
            }
        }
    }
    .right-side {
        width: 45%;
        box-shadow: 0px 0px 10px 0px #00000029;
        padding: 50px 35px;
        .section-form h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-black;
            padding-bottom: 35px;
        }
    }
    @media screen and (max-width: 1870px) {
        padding: 100px 4%;
        .left-side {
            .heures-info {
                .left {
                    width: 50%;
                    margin-right: 30px;
                }
            }
        }
        @media screen and (max-width: 1770px) {
            .left-side {
                .heures-info {
                    flex-direction: column-reverse;
                    .right {
                        margin: 0 0 75px 0;
                    }
                }
            }
            @media screen and (max-width: $size-md-max) {
                margin: -175px 7% 0 7%;
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0 0 75px 0;
                    }
                    .right-side {
                        width: 80%;
                        margin: auto;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        margin: -100px 7% 0 7%;
                        padding: 150px 4% 100px 4%;
                        .right-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            margin: 0;
                            padding: 75px 5%;
                            .left-side .heures-info .left {
                                width: 70%;
                                margin-right: 0;
                            }
                            .right-side {
                                box-shadow: none;
                                padding: 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
